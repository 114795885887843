import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { get } from 'lodash';
import * as Yup from 'yup';

import { useModal, useNotify, format } from '@moved/services';
import { StyledForm, DynamicField, RenderFields, AccordionList, AtomSpinner, Notebox } from '@moved/ui';

import { getVendorList, sendJobOffer } from '../../actions';
import { useSendJobOfferPending, useVendorList, useVendorListPending } from '../../actions/selectors';
import { hasAcceptedVendor } from '../../helpers';

import CSS from './SendJobOfferModal.module.scss';

const validation = Yup.object().shape({
  vendor_id: Yup.number().integer()
    .required('Must choose a vendor from the list'),

  service_date: Yup.string()
    .required('Service Date is required.'),
  time_preference: Yup.string()
    .required('Time preference is required.'),

  rate_type: Yup.string()
    .required(),
  rate: Yup.number().integer()
    .when('rate_type', {
      is: 'hourly',
      then: Yup.number()
        .min(100, 'Must be at least $1')
        .required('Required'),
    }),
  minimum: Yup.number().integer()
    .when('rate_type', {
      is: 'hourly',
      then: Yup.number().required('Required'),
    }),
  crew_size: Yup.number().integer()
    .when('rate_type', {
      is: 'hourly',
      then: Yup.number()
        .min(2, 'Minimum of 2')
        .max(8, 'Maximum of 8')
        .required('Required'),
    }),
  estimated_price: Yup.number().integer()
    .when('rate_type', {
      is: 'flat',
      then: Yup.number()
        .min(100, 'Must be at least $1')
        .required('Required'),
    }),
});

export const SendJobOfferModal = ({ order={} }) => {
  const modal = useModal();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { all: vendorList=[] } = useVendorList();
  const vendorListPending = useVendorListPending();
  const pending = useSendJobOfferPending();
  const [offer, setOffer] = useState({
    vendor_id: '',
    service_date: moment().isSameOrBefore(order.service_date,'day') ? order.service_date : '',
    time_preference: get(order, 'mover_booking.time_preference') || 'Morning',
    rate_type: order.rate_type || 'hourly',
    rate: order.rate || 0,
    minimum: order.minimum || 0,
    crew_size: order.crew_size || 3,
    estimated_price: order.estimated_price || 0,
  });

  useEffect(() => {
    dispatch(getVendorList())
      .catch(error => notify.error(format.error(error)));
  },[]); // eslint-disable-line

  if(!vendorList || vendorListPending) return <AtomSpinner/>;

  const vendorField = {
    type: 'select',
    name: 'vendor_id',
    label: 'Choose Vendor',
    placeholder: 'Start typing...',
    value: offer.vendor_id,
    options: vendorList.map(vendor => ({label: vendor.name, value: vendor.id})),
  };

  const schedulingFields = [
    {
      type: 'datePicker',
      name: 'service_date',
      value: offer.service_date,
      minDate: moment().format('YYYY-MM-DD'),
      label: 'Pickup Date',
      placeholder: 'Select a date...',
    },
    {
      type: 'slideToggle',
      name: 'time_preference',
      value: offer.time_preference,
      options: [
        {
          label: 'Morning',
          value: 'Morning',
        },
        {
          label: 'Afternoon',
          value: 'Afternoon',
        },
        {
          label: 'No preference',
          value: 'No preference',
        },
      ],
    },
  ];

  const pricingFields = [
    {
      type: 'slideToggle',
      name: 'rate_type',
      value: offer.rate_type,
      options: [
        {
          label: 'Hourly',
          value: 'hourly',
        },
        {
          label: 'Flat',
          value: 'flat',
        }
      ],
    },
    offer.rate_type === 'hourly' && {
      label: 'Hourly rate ($)',
      type: 'currency',
      name: 'rate',
      value: offer.rate,
      half: true,
    },
    offer.rate_type === 'hourly' && {
      label: 'Minimum cost ($)',
      type: 'currency',
      name: 'minimum',
      value: offer.minimum,
      half: true,
    },
    offer.rate_type === 'hourly' && {
      label: 'Crew size',
      type: 'integer',
      name: 'crew_size',
      value: offer.crew_size,
      half: true,
    },
    offer.rate_type === 'flat' && {
      label: 'Estimated Price',
      type: 'currency',
      name: 'estimated_price',
      value: offer.estimated_price,
      half: true,
    },
  ].filter(v => v);

  const handleSubmit = ({ vendor_id, service_date, time_preference, rate_type, ...prices }) => {
    const updatedData = { vendor_id, service_date, time_preference, rate_type };
    if(rate_type === 'hourly') {
      updatedData.rate = prices.rate;
      updatedData.minimum = prices.minimum;
      updatedData.crew_size = prices.crew_size;
    }
    if(rate_type === 'flat') {
      updatedData.estimated_price = prices.estimated_price;
    }

    dispatch(sendJobOffer(order.id, updatedData))
      .then(() => {
        notify.default(`Job offer sent to ${vendorList.find(vendor => vendor.id === updatedData.vendor_id).name}`);
        modal.close();
      })
      .catch(error => notify.error(format.error(error)));
  };

  return (<>
    <h3 className={CSS.title}>Send New Job Offer</h3>
    <StyledForm
      id='offer-form'
      formStyle='underline'
      onSubmit={handleSubmit}
      onChange={setOffer}
      initialValues={offer}
      validation={validation}
    >
      {(formik) => (
      <>
        { hasAcceptedVendor(order) && (
          <Notebox
            title='Changing a confirmed vendor'
            body={`${get(order,'vendor.name')} has already been introduced to the
              customer as the confirmed vendor for this service order. Sending a
              new offer will automatically remove them from this job. Please
              ensure all parties are aware of this change before proceeding.`}
            icon={{
              symbol: 'Warning-2',
              library: 'code'
            }}
            color='yellow'
            className='marginBottom-24'
          />
        )}
        <section className={classNames(CSS.section)}>
          <DynamicField input={vendorField} form={formik} formStyle='underline' />
        </section>
        <AccordionList
          className={classNames(CSS.section)}
          allowMultiple={true}
          panels={[{
            label: (<span className='labelL'>Scheduling Details</span>),
            content: (
              <section className={classNames(CSS.section)}>
                <RenderFields fields={schedulingFields} form={formik} />
              </section>
            ),
            // if existing date is invalid, start expanded
            initial: moment().isSameOrAfter(order.service_date,'day'),
          },{
            label: (<span className='labelL'>Pricing Details</span>),
            content: (
              <section className={classNames(CSS.section)}>
                <RenderFields fields={pricingFields} form={formik} />
              </section>
            ),
          }]}
        />
        <section className={classNames(CSS.actions)}>
          <span className={'btn-gray mr-15'} onClick={modal.close}>Cancel</span>
          <button className={classNames('btn-primary',{loading:pending})} type='submit' form='offer-form'>Send</button>
        </section>
      </>
      )}
    </StyledForm>
  </>);

}
